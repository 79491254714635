import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import { NO_RESET_WHITE_LIST } from '@/constants'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard/analysis',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard/analysis',
    name: 'Dashboard',
    meta: {
      title: '概况',
      icon: 'svg-icon:menu-analysis',
      alwaysShow: false
    },
    children: [
      {
        path: 'analysis',
        component: () => import('@/views/Dashboard/Analysis.vue'),
        name: 'Analysis',
        meta: {
          icon: 'svg-icon:menu-analysis',
          title: '概况',
          affix: true
        }
      }
    ]
  },
  {
    path: '/release',
    component: Layout,
    redirect: '/release/materialList',
    name: 'Release',
    meta: {
      title: '智能商机发布',
      icon: 'svg-icon:menu-submit',
      alwaysShow: false
    },
    children: [
      {
        path: 'materialList',
        component: () => import('@/views/Release/MaterialConfig/MaterialList/MaterialList.vue'),
        name: 'MaterialList',
        meta: {
          icon: 'radix-icons:dot-filled',
          title: '素材配置'
        },

        children: [
          {
            path: 'addProdSvc',
            component: () => import('@/views/Release/MaterialConfig/AddEditProdSvc/Add.vue'),
            name: 'AddProdSvc',
            meta: {
              title: '添加产品服务',
              hidden: true
            }
          },
          {
            path: 'editProdSvc',
            component: () => import('@/views/Release/MaterialConfig/AddEditProdSvc/Edit.vue'),
            name: 'EditProdSvc',
            meta: {
              title: '编辑产品服务',
              hidden: true
            }
          },
          {
            path: 'materialMng',
            component: () => import('@/views/Release/MaterialConfig/MaterialMng/MaterialMng.vue'),
            name: 'MaterialMng',
            meta: {
              title: '素材管理',
              hidden: true
            },
            beforeEnter: (to, _from, next) => {
              if (to.query.psid) {
                next()
              } else {
                next({
                  name: 'MaterialList'
                })
              }
            },
            children: [
              {
                path: 'addSentence',
                component: () =>
                  import('@/views/Release/MaterialConfig/MaterialMng/AddSentence.vue'),
                name: 'AddSentence',
                meta: {
                  title: '手动创建整句素材',
                  hidden: true
                }
              },
              {
                path: 'editSentence',
                component: () =>
                  import('@/views/Release/MaterialConfig/MaterialMng/EditSentence.vue'),
                name: 'EditSentence',
                meta: {
                  title: '修改整句素材',
                  hidden: true
                }
              }
            ]
          }
        ]
      },
      {
        path: 'vocabulary',
        component: Layout,
        name: 'ExpandVocabulary',
        meta: {
          icon: 'radix-icons:dot-filled',
          title: '智能拓词',
          alwaysShow: true
        },
        children: [
          {
            path: 'expandWordCombination',
            component: () =>
              import('@/views/Release/Vocabulary/ExpandWordCombination/ExpandWordCombination.vue'),
            name: 'ExpandWordCombination',
            meta: {
              title: '智能拓词组合'
            }
          },
          {
            path: 'preciseWordExtension',
            component: () =>
              import('@/views/Release/Vocabulary/PreciseWordExtension/PreciseWordExtension.vue'),
            name: 'PreciseWordExtension',
            meta: {
              title: '精准拓词'
            }
          },
          {
            path: 'smartWordExpand',
            component: () =>
              import('@/views/Release/Vocabulary/SmartWordExpand/SmartWordExpand.vue'),
            name: 'SmartWordExpand',
            meta: {
              title: '智能拓词推荐'
            }
          },
          {
            path: 'wordBankManagement',
            component: () => import('@/views/Release/WordBankManagement/WordBankManagement.vue'),
            name: 'WordBankManagement',
            meta: {
              title: '拓词库管理'
            }
          }
        ]
      },
      {
        path: 'areaGroupConfig',
        component: () => import('@/views/Release/AreaGroupConfig/AreaGroupConfig.vue'),
        name: 'AreaGroupConfig',
        meta: {
          title: '常用地区组配置'
        },
        children: []
      },
      {
        path: 'scheduledRelease',
        component: () => import('@/views/Release/ScheduledRelease/ScheduledRelease.vue'),
        name: 'scheduledRelease',
        meta: {
          icon: 'radix-icons:dot-filled',
          title: '自动发布设置'
          // alwaysShow: true
        },
        children: []
      }
    ]
  },

  {
    path: '/promotionOutcome',
    component: Layout,
    name: 'PromotionOutcome',
    redirect: '/promotionOutcome/treasure',
    meta: {
      title: '粨宝商机推广结果',
      icon: 'svg-icon:menu-promotion'
    },
    children: [
      {
        path: 'treasure',
        component: () => import('@/views/Release/PromotionOutcome/treasure.vue'),
        name: 'Treasure',
        meta: {
          title: '粨宝商机推广结果',
          icon: 'svg-icon:menu-promotion'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !NO_RESET_WHITE_LIST.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
